import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Seo from '../../components/Seo';
import Layout from '../../layouts/index';
import quote from '../../images/quote.png';
import jordan from '../../images/Skwirk.png';
import MATT from '../../images/Matt-Hinds.png';
import TAREK from '../../images/cheq.jpg';
import LYLE from '../../images/Lyle-Ralph.png';
import JOE from '../../images/Joe-Wakim.jpg';
import MARK from '../../images/Mark-Youman.png';
import comment from '../../images/comment.png';

import A1 from '../../images/home-page/logos-png/A1.png';
import Beforepay from '../../images/home-page/logos-png/Beforepay.png';
import effi from '../../images/home-page/logos-png/effi.png';
import oxygen from '../../images/home-page/logos-png/oxygen.png';
import pokitpal from '../../images/home-page/logos-png/pokitpal.png';

import Lilardia from '../../images/home-page/logos-png/Lilardia.png';
import StideEquity from '../../images/home-page/logos-png/StideEquity.png';
import Utransport from '../../images/home-page/logos-png/Utransport.png';
import C2zero from '../../images/home-page/logos-png/C2zero.png';
import GigaClear from '../../images/home-page/logos-png/GigaClear.png';
import Symbox from '../../images/home-page/logos-png/Symbox.png';
import iGoDirect from '../../images/home-page/logos-png/iGoDirect.png';
import Mable from '../../images/home-page/logos-png/Mable.png';

import Simble from '../../images/home-page/logos-png/Simble.png';
import Solcast from '../../images/home-page/logos-png/Solcast.png';
import inovett from '../../images/home-page/logos-png/inovett.png';

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));
const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplay: true,
  autoplayHoverPause: true,
  margin: 10,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    992: {
      items: 1,
    },
  },
};

// import Quote from '../../components/Quote';

const Testimonials = (props) => (
  <Layout bodyClass="page-testimonials">
    <Seo title="Testimonials" description="What Our Customers Say..." />
    <div className="intro intro-banner" id="up">
      <div className="intro-banner-img">
        <Img
          fluid={props.data.bannerImg.childImageSharp.fluid}
          loading="eager"
          fadeIn={true}
          alt="Banner Image"
        />
      </div>
      <div className="container">
        <div className="row main-page">
          <div className="col-12">
            <h1 className="page-title">What Our Customers Say...</h1>
            <h2 className="page-subtitle">
              We are invested in building great relationships with our clients
              and partners
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="blockquote">
            <img className="img-fluid mb-3" alt="qutoe" src={quote} />
            <div className="blockquote-txt">
              iTelaSoft consistently deliver to us a high quality result from
              both our development and support tasks. We have had a long term
              relationship and found that the staff are able to enhance our own
              skills and to communicate to us the effectiveness of facets of our
              development tasks, as well as identifying areas where the task
              needs to strengthened.
            </div>
            <div className="blockquote-wrap">
              <div className="cl-img">
                <img className="img-fluid" alt="JORDAN BUTLER" src={jordan} />
              </div>
              <div className="wrap-cl">
                <div className="cl-name">JORDAN BUTLER</div>
                <div className="cl-detail">Skwirk - Australia</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-12 col-lg-4 col-md-12 mt-lg-0 mt-4 text-center">
            <div className="blockquote-two">
              <div className="blockquote-txt">
                I recently engaged iTelaSoft to help us launch a new financial
                services product. iTelaSoft were able to quickly assemble a
                team, process and refine the requirements with us, and tender a
                solution with costings. iTelaSoft integrated with our team
                effectively to deliver the solution as specified with little
                issue, and post implementation support was great. I would work
                with the iTelaSoft team again on future engagements.
              </div>
              <div className="blockquote-wrap">
                {/* <div className="cl-img-two">
                  <img className="img-fluid" alt="TAREK AYOUB" src={TAREK} />
                </div> */}
                <div className="wrap-cl">
                  <div className="cl-name">GIULIO CAMPOBASSI</div>
                  <div className="cl-detail">Jingl - Australia</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-12 text-center">
            <div className="blockquote-two">
              <div className="blockquote-txt">
                The iTelaSoft team were fantastic in providing affordable
                pricing and delivering a high quality product. Their customer
                service was fantastic - the team was very friendly, incredibly
                responsive and creative in the way that they responded to
                issues. I would highly recommend the iTelaSoft team, they
                exceeded expectations and I am happy to now call them friends.
              </div>
              <div className="blockquote-wrap">
                <div className="cl-img-two">
                  <img className="img-fluid" alt="MATT HINDS" src={MATT} />
                </div>
                <div className="wrap-cl">
                  <div className="cl-name">MATT HINDS</div>
                  <div className="cl-detail">Agcrowd - Australia</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-12 mt-lg-0 mt-4 text-center">
            <div className="blockquote-two">
              <div className="blockquote-txt">
                iTelasoft gave us access to resources on demand, who assisted us
                with iOS development, Android development, UX/UI, and QA
                testing. This has allowed us to focus on delivering an amazing
                product and servicing our growing number of users. The iTelasoft
                team essentially became an extension of our team. Absolutely
                amazing service delivery.
              </div>
              <div className="blockquote-wrap">
                <div className="cl-img-two">
                  <img className="img-fluid" alt="TAREK AYOUB" src={TAREK} />
                </div>
                <div className="wrap-cl">
                  <div className="cl-name">TAREK AYOUB</div>
                  <div className="cl-detail">Cheq - Australia</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="my-3">
        <div className="container mt-6">
          <section className="my-6 partners">
            <div className="container mt-6">
              <h2 className="title-2 text-center text-dark mb-4">
                IT HAS BEEN AN EXCITING JOURNEY WITH
              </h2>
              {/* <hr /> */}

              {/* <Carousel /> */}
              <div className="img-grid ">
          <div className="card">
          <a href="https://a1spareparts.com.au/" target="_blank">
                  <img className="img-partner" alt="Partners" src={A1} /> </a>
                </div>
          <div className="card">
            <a href="https://www.beforepay.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Beforepay} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.effi.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={effi} />
            </a>
          </div>
          <div className="card">
            <a href="https://oxygen.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={oxygen} />
            </a>
          </div>
          <div className="card">
            <a href="https://pokitpal.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={pokitpal} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.lilardiacapital.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Lilardia} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.strideequity.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={StideEquity} />
            </a>
          </div>
          <div className="card">
            <a href="https://utransport.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Utransport} />
            </a>
          </div>
          <div className="card">
          <a href="https://www.c2zero.net/" target="_blank">
                  <img className="img-partner" alt="Partners" src={C2zero} /></a>
                </div>
          <div className="card">
            <a href="https://gigaclear.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={GigaClear} />
            </a>
          </div>
          <div className="card">
            <a href="https://www.symbox.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Symbox} />
            </a>
          </div>
          <div className="card">
            <a href="https://igodirect.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={iGoDirect} />
            </a>
          </div>
          <div className="card">
            <a href="https://mable.com.au/" target="_blank">
              <img className="img-partner" alt="Partners" src={Mable} />
            </a>
          </div>
          <div className="card">
            <a href="https://simblegroup.com/" target="_blank">
              <img className="img-partner" alt="Partners" src={Simble} />
            </a>
          </div>
          <div className="card">
          <a href="https://solcast.com/" target="_blank">
                  <img className="img-partner" alt="Partners" src={Solcast} /> </a>
                </div>
          <div className="card">
            <a href="https://www.inovett.co.uk/" target="_blank">
              <img className="img-partner" alt="Partners" src={inovett} />
            </a>
          </div>
        </div>
            </div>
          </section>
        </div>
      </section>
      <div className="row mb-6">
        <div className="col-12 col-lg-4 col-md-12 text-center">
          <div className="blockquote-three">
            <div className="blockquote-txt">
              The iTelaSoft team created an incredible solution for Canada’s
              largest retail store. Thank you! ... I’m confident this is the
              first of many projects we’ll do together!
            </div>
            <div className="blockquote-wrap">
              <div className="cl-img-two">
                <img className="img-fluid" alt="LYLE RALPH" src={LYLE} />
              </div>
              <div className="wrap-cl">
                <div className="cl-name">LYLE RALPH</div>
                <div className="cl-detail">eResults - Canada</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-12 text-center">
          <div className="blockquote-three">
            <div className="blockquote-txt">
              We’ve been dealing with iTelaSoft for a few years now, and we’re
              very pleased with the service, and always happy with the help we
              get from the team.
            </div>
            <div className="blockquote-wrap">
              <div className="cl-img-two">
                <img className="img-fluid" alt="JOE WAKIM" src={JOE} />
              </div>
              <div className="wrap-cl">
                <div className="cl-name">JOE WAKIM</div>
                <div className="cl-detail">Lightning Box Games - Sydney</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-12  text-center">
          <div className="blockquote-three">
            <div className="blockquote-txt">
              These guys really know their stuff. They’re highly professional
              and highly dedicated. Well done guys, you’re doing great!
            </div>
            <div className="blockquote-wrap">
              <div className="cl-img-two">
                <img className="img-fluid" alt="MARK YOUMAN" src={MARK} />
              </div>
              <div className="wrap-cl">
                <div className="cl-name">MARK YOUMAN</div>
                <div className="cl-detail">One Community - Australia</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <section className="wrap-carouselTwo">
      <div className="container-fluid layer-carouselTwo"> */}
    {/* <CarouselTwo /> */}
    {/* <img className="img-fluid img-caro" src={imgCarouselTwo} /> */}
    {/* </div>
    </section> */}
    <section className="wrap-carouselTwo">
      <div className="container-fluid layer-carouselTwo">
        <OwlCarousel className="owl-theme" {...options}>
          <div class="item">
            <div className="d-flex justify-content-center align-items-center">
              <div className="blockquote-two">
                <div className="blockquote-txt">
                  I have been working with iTelasoft since 3 years and I will
                  recommend iTelasoft for sure. iTelasoft has fantastic
                  management , great employees with technical capabilities and
                  fully committed to work and delivery. iTelasoft is very
                  professional and delivered great products and services.
                </div>
                <div className="blockquote-wrap">
                  <div className="wrap-cl mt-3">
                    <div className="cl-name">Saurabh Singh</div>
                    <div className="cl-detail">SLR Consultants Ltd</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div className="d-flex justify-content-center align-items-center">
              <div className="blockquote-two">
                <div className="blockquote-txt">
                  I've worked iTelaSoft on a number of projects and they are
                  very professional and outcome focused. Great to work with.
                </div>
                <div className="blockquote-wrap">
                  <div className="wrap-cl mt-3">
                    <div className="cl-name">Gary Cobain</div>
                    <div className="cl-detail">Pokitpal</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div className="d-flex justify-content-center align-items-center">
              <div className="blockquote-two">
                <div className="blockquote-txt">
                  iTelasoft went above and beyond my expectations to deliver.
                  The team were very quick to adapt to scope changes. They also
                  had great ideas that were applied in the build. Overall a
                  professional and enjoyable team to work with. Look forward to
                  working with the team again in the future.
                </div>
                <div className="blockquote-wrap">
                  <div className="wrap-cl mt-3">
                    <div className="cl-name">Kris Kolley</div>
                    <div className="cl-detail">Snowbo</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </section>
    <div className="container pb-6 mt-7 mb-5">
      <div className="row text-center">
        <div className="col-12 col-md-3">
          
          <img className="img-fluid comment-ic" alt="comment" src={comment} />
        </div>
        <div className="col-12 col-md-6 text-center">
          <div className="title-1 pb-3">Contact us for a free consultation</div>
          <p>Let us discuss how we can help you.</p>
        </div>
        <div className="col-12 col-md-3 mt-3">
          
          <a className="btn btn-primary" href="/contact/">
            CONTACT US
          </a>
        </div>
      </div>
    </div>
  </Layout>
);
export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "testibnr.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Testimonials;
